"use strict";

var $window = jQuery(window);
var $ajaxForm = {
	validation: function () { },
	beforeSubmit: null,
	success: null,
	complete: null,
};



jQuery(function ($) {

    $('.fs-custom-select').fSelect();

    function hideNav() {
      navBar.addClass("is-hidden");
    }

    function showNav() {
      navBar.removeClass("is-hidden");
    }



    $('#countrySelect').on('change', function() {
      var selectedCountry = $(this).val();
      console.log('Selected Country:', selectedCountry);
      
      if (selectedCountry !== '') {
        var redirectTo = './' + selectedCountry + '.php';
        console.log('Redirecting to:', redirectTo);
        
        window.location.href = redirectTo;
      }

    });

    $('#lead_form, #feedback_form').on('submit', function (e) {
      e.preventDefault();

      const modal = $(this).closest('.modal');
      const type = ($(this).data('type') !== undefined) ? $(this).data('type') : 'feedback';
      var formData = new FormData(this);
      formData.append('type', type);
      console.log(formData);
    
      $.ajax({
        type: 'POST',
        url: '../phpmailer/mailer.php',
        data: formData,
        processData: false,
        contentType: false,
        success: function (response) {
          // console.log(typeof response);
          if (response.success == true) {
              modal.modal('hide');
              console.log('Email sent successfully');
          } else {
              console.error('Error sending email:', response.error);
              modal.modal('hide');
          }
        },      
        error: function () {
            console.error('Failed to send email');
        },
      });

        localStorage.setItem('lead_form_completed', 'true');
      });

      const showRegisterModal = localStorage.getItem('lead_form_completed') !== 'true';
      const registerModal = new bootstrap.Modal('#registerModal', { backdrop: 'static', keyboard: false });

      if (showRegisterModal) {
          registerModal.show();
      }

});

